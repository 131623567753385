import { render, staticRenderFns } from "./apply_status_record.vue?vue&type=template&id=32c7530e&scoped=true&"
import script from "./apply_status_record.vue?vue&type=script&lang=js&"
export * from "./apply_status_record.vue?vue&type=script&lang=js&"
import style0 from "./apply_status_record.vue?vue&type=style&index=0&id=32c7530e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c7530e",
  null
  
)

export default component.exports